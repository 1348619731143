import { ApiData } from 'interfaces/api';
import { UploadedFile } from 'interfaces/file';
import { User, UserSignUpFields, UserAuthResponse } from 'interfaces/user';

export abstract class UserService {
  abstract getCurrentUser(token: string): Promise<User>;
  abstract updateCurrentUser(
    userId: number,
    data: UserSignUpFields
  ): Promise<User>;
  abstract sendForgotPasswordEmail(email: string): Promise<boolean>;
  abstract login(email: string, password: string): Promise<UserAuthResponse>;
  abstract resetPassword(
    code: string,
    password: string,
    password2: string
  ): Promise<UserAuthResponse>;
  abstract signUp(newUser: UserSignUpFields): Promise<UserAuthResponse>;
  abstract uploadAvatar(file: File, refId: number): Promise<UploadedFile>;
  abstract getAvatarUrl(
    cover: ApiData<UploadedFile> | undefined,
    format: 'thumbnail' | 'small' | 'medium' | 'large'
  ): string;
  abstract logout(): void;
}
