import { Chart } from 'interfaces/chart';
import { QueryParams, Strapi } from './strapi';
import { ApiCollection, ApiDocument } from 'interfaces/api';
import { ChartService } from '../abstract/chart';
import { ContentType } from 'services/abstract';

export class RemoteChartService extends ChartService {
  constructor(private strapiService: Strapi) {
    super();
  }

  async createChart(data: Chart): Promise<ApiDocument<Chart>> {
    return await this.strapiService.create<Chart>(ContentType.CHARTS, data);
  }

  async getChartById(id: number): Promise<ApiDocument<Chart>> {
    return await this.strapiService.getById<Chart>(ContentType.CHARTS, id);
  }

  async getChartsByCartoPage(
    pageCartoId: number
  ): Promise<ApiCollection<Chart>> {
    return await this.strapiService.get<Chart>(ContentType.CHARTS, {
      filters: {
        page_carto: { id: { $eq: pageCartoId } },
      } as QueryParams['filters'],
    });
  }

  async updateChart(
    id: number,
    data: Partial<Chart>
  ): Promise<ApiDocument<Chart>> {
    return await this.strapiService.update(ContentType.CHARTS, id, { data });
  }

  async deleteChart(id: number) {
    return await this.strapiService.delete<Chart>(ContentType.CHARTS, id);
  }
}
