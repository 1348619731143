import { TitlePageCartoEdit } from 'components/TitlePageCartoEdit/TitlePageCartoEdit';
import { FC } from 'react';
import { PageCartoChartPanel } from './PageCartoChartPanel/PageCartoChartPanel';
import { PageCartoMap } from './PageCartoMap';
import { PageCartoPanels } from './PageCartoPanels';

export const PageCartoEditor: FC = () => {
  return (
    <div className="grid grid-cols-12 h-full">
      <div className="col-span-6 p-2 relative">
        <div className="w-full p-2 absolute z-50 flex bg-white bg-opacity-50">
          <TitlePageCartoEdit />
        </div>
        <PageCartoMap canEdit={true} />
        <div className="w-[50%] absolute z-50 bottom-0 right-0 opacity-90 translate-y-[-25%]">
          <PageCartoChartPanel />
        </div>
      </div>
      <div className="col-span-6 p-2 box-border	">
        <PageCartoPanels />
      </div>
    </div>
  );
};
