import { ContentType, DatastoreService } from 'services/abstract/index';
import { getServices } from 'services/index';

const SERVICES = getServices();

export const useService = <T extends ContentType>(service: T) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return SERVICES[service] as DatastoreService[T];
};
