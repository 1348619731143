import { RawDatum } from 'interfaces/chart';
import { Column } from 'interfaces/column';
import { DataFragmentStub } from 'interfaces/data-fragment';
import { DatasetStub } from 'interfaces/dataset';
import { Strapi } from './strapi';
import { ApiDocument } from 'interfaces/api';
import { DatasetService } from '../abstract/dataset';
import { ContentType } from 'services/abstract';

export class RemoteDatasetService extends DatasetService {
  constructor(private strapiService: Strapi) {
    super();
  }

  async addDataToPageCarto(
    pageCartoId: number,
    name: string,
    data: DatasetStub,
    columns: Column[]
  ): Promise<ApiDocument<DataFragmentStub>> {
    const dataset = await this.strapiService.create<DatasetStub>(
      ContentType.DATASET,
      data
    );

    return await this.strapiService.create<DataFragmentStub>(
      ContentType.DATA_FRAGMENT,
      {
        name: name,
        columns: columns,
        dataset: dataset.data.id,
        page_carto: pageCartoId,
      }
    );
  }

  async uploadCsv(file: File): Promise<void> {
    await this.strapiService.uploadFile(file, 'api::dataset.dataset');
  }

  async getPageCartoData(id: number): Promise<RawDatum[]> {
    return await this.strapiService.read<RawDatum[]>(`page-cartos/${id}/data`);
  }
}
