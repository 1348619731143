import { ContentType, DatastoreService } from 'services/abstract';
import { RemoteChartService } from './chart';
import { RemoteDatasetService } from './dataset';
import { RemoteEquationService } from './equation';
import { RemoteGeoMapService } from './geo-map';
import { RemoteIndicatorService } from './indicator';
import { RemotePageCartoService } from './page-carto';
import { RemoteSnapshotService } from './snapshot';
import { strapi } from './strapi';
import { RemoteTagService } from './tag';
import { RemoteUserService } from './user';

const SERVICES: DatastoreService = {
  [ContentType.CHARTS]: new RemoteChartService(strapi),
  [ContentType.DATASET]: new RemoteDatasetService(strapi),
  [ContentType.DATA_FRAGMENT]: new RemoteDatasetService(strapi),
  [ContentType.EQUATION]: new RemoteEquationService(strapi),
  [ContentType.GEO_MAPS]: new RemoteGeoMapService(strapi),
  [ContentType.INDICATOR]: new RemoteIndicatorService(strapi),
  [ContentType.PAGE_CARTOS]: new RemotePageCartoService(strapi),
  [ContentType.SNAPSHOTS]: new RemoteSnapshotService(strapi),
  [ContentType.TAGS]: new RemoteTagService(strapi),
  [ContentType.USERS]: new RemoteUserService(strapi),
};

export default SERVICES;
