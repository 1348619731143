import { ApiData, ApiDocument } from 'interfaces/api';
import { IndicatorStub } from 'interfaces/indicator';

export abstract class IndicatorService {
  abstract getIndicatorsByPageCartoId(
    pageCarto: number
  ): Promise<ApiData<IndicatorStub>[]>;
  abstract addIndicatorToPageCarto(
    pageCartoId: number,
    indicator: IndicatorStub
  ): Promise<ApiDocument<IndicatorStub>>;
}
