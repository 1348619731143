import { Equation } from 'interfaces/equation';
import { Strapi } from './strapi';
import { ApiCollection } from 'interfaces/api';
import { EquationService } from '../abstract/equation';
import { ContentType } from 'services/abstract';

export class RemoteEquationService extends EquationService {
  constructor(private strapiService: Strapi) {
    super();
  }

  async getEquations(): Promise<ApiCollection<Equation>> {
    return await this.strapiService.get<Equation>(ContentType.EQUATION);
  }
}
