import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid';
import { ApiErrorAlert } from 'components/Alert/ApiErrorMessage';
import { Badge } from 'components/Badge/Badge';
import { Button } from 'components/Button/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import { LoadingMessage } from 'components/Loader/LoadingMessage';
import excerptHtml from 'excerpt-html';
import { useAuth } from 'hooks/useAuth';
import { useConfirmModal } from 'hooks/useModal';
import { useService } from 'hooks/useService';
import { useToast } from 'hooks/useToast';
import { ApiData, ApiError } from 'interfaces/api';
import { PageCarto } from 'interfaces/page-carto';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { ContentType } from 'services/abstract';

const PageCartoItem: React.FC<ApiData<PageCarto>> = ({
  id,
  name,
  owner,
  tags,
  map,
  cover,
  html,
}) => {
  const [canEditOrDelete, setCanEditOrDelete] = useState(false);
  const { user } = useAuth();
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const pageCartoService = useService(ContentType.PAGE_CARTOS);
  const navigate = useNavigate();

  useEffect(() => {
    if (owner?.id === user?.id) {
      setCanEditOrDelete(true);
    } else {
      setCanEditOrDelete(false);
    }
  }, [owner?.id, user]);

  const {
    mutateAsync: deletePageCartoItem,
    isLoading,
    isError,
    error,
  } = useMutation({
    mutationFn: async (id: number) => {
      return await pageCartoService.deletePageCarto(id);
    },
    onSuccess: () => {
      addToast({
        title: `PageCarto ${name} supprimée`,
        description: 'PageCarto supprimée avec succès',
        type: 'success',
      });
      queryClient.invalidateQueries({
        queryKey: ['latest-page-carto'],
      });
      queryClient.invalidateQueries({ queryKey: ['page-carto-user'] });
    },
  });

  const { showConfirmModal } = useConfirmModal(
    'Confirmation',
    'Êtes-vous sûr de vouloir supprimer cette PageCarto ?',
    async () => await deletePageCartoItem(id)
  );

  if (isLoading) {
    return <LoadingMessage data-testid="loading-message" />;
  }

  if (isError) {
    return (
      <ApiErrorAlert error={error as ApiError} data-testid="error-message" />
    );
  }

  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg">
      <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden bg-gray-100 xl:aspect-w-7 xl:aspect-h-8 relative">
        <Link to={`/page-carto/${id}`}>
          <img
            src={pageCartoService.getImageUrl(cover, 'small')}
            className="h-48 w-full object-contain group-hover:opacity-75"
          />
        </Link>
        {canEditOrDelete === true ? (
          <ButtonGroup className="absolute bottom-4 right-4">
            <Button
              color="light"
              size="sm"
              icon={PencilSquareIcon}
              data-testid="editIcon"
              onClick={() => navigate(`/page-carto/${id}/edit`)}
            ></Button>
            <Button
              color="light"
              size="sm"
              icon={TrashIcon}
              data-testid="deleteIcon"
              onClick={showConfirmModal}
            ></Button>
          </ButtonGroup>
        ) : (
          // <div className="flex space-x-2">
          //   <Link to={`/page-carto/${id}/edit`}>
          //     <PencilSquareIcon className="h-7 w-7" data-testid="editIcon" />
          //   </Link>
          //   <Link to={'#'}>
          //     <TrashIcon
          //       onClick={showConfirmModal}
          //       className="h-7 w-7"
          //       data-testid="deleteIcon"
          //     />
          //   </Link>
          // </div>
          ''
        )}
      </div>
      <div className="p-3">
        <h2>{name}</h2>
        <div className="text-gray-500">
          <div className="text-sm my-1">
            <div className="my-1 font-bold">Carte : {map?.name}</div>
            <p>{html && excerptHtml(html)}</p>
            <div className="text-xs mt-2">Créer par : {owner?.username}</div>
          </div>
          <div className="mt-3">
            {tags.map((tag, index) => {
              return (
                <Badge className="inline-block" key={index}>
                  {tag.name}
                </Badge>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageCartoItem;
