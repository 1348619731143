import { TagsSelector } from 'components/TagsSelector/TagsSelector';
import { usePageCarto } from 'hooks/usePageCarto';
import { useService } from 'hooks/useService';
import { useToast } from 'hooks/useToast';
import { ApiError } from 'interfaces/api';
import { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { ContentType } from 'services/abstract';
export const PageCartoTags: FC = () => {
  const { data } = usePageCarto();
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>(
    data?.data.tags.map((tag) => tag.id) || []
  );
  const { addToast } = useToast();
  const pageCartoService = useService(ContentType.PAGE_CARTOS);

  const updateTagsPageCarto = useMutation<void, ApiError, number[]>({
    mutationFn: async (selectedTags: number[]) => {
      await pageCartoService.updatePageCarto(data?.data.id || 0, {
        tags: selectedTags,
      });
    },
    onSuccess: async () => {
      addToast({
        title: 'Mise à jour des tags',
        description: 'Les tags de la pageCarto ont été mis à jour avec succès',
        type: 'success',
      });
    },
  });

  const handleTagsChange = (selectedTagIds: number[]) => {
    setSelectedTagIds(selectedTagIds);
    updateTagsPageCarto.mutate(selectedTagIds);
  };

  return <TagsSelector onChange={handleTagsChange} selected={selectedTagIds} />;
};
