import classNames from 'classnames';
import type { ComponentProps, FC } from 'react';

export type RadioProps = Omit<ComponentProps<'input'>, 'type'>;

export const Radio: FC<RadioProps> = ({ className, ...props }) => (
  <input
    className={classNames(
      'h-4 w-4 border border-gray-300 focus:ring-2 focus:ring-blue-500 cursor-pointer focus:border-current focus:ring-0',
      className
    )}
    type="radio"
    {...props}
  />
);
