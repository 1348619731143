import { ApiDocument } from 'interfaces/api';
import { RawDatum } from 'interfaces/chart';
import { Column } from 'interfaces/column';
import { DataFragmentStub } from 'interfaces/data-fragment';
import { DatasetStub } from 'interfaces/dataset';

export abstract class DatasetService {
  abstract addDataToPageCarto(
    pageCartoId: number,
    name: string,
    data: DatasetStub,
    columns: Column[]
  ): Promise<ApiDocument<DataFragmentStub>>;
  abstract uploadCsv(file: File): Promise<void>;
  abstract getPageCartoData(id: number): Promise<RawDatum[]>;
}
