import Well from 'components/Layout/Well';
import { ScaleQuantile } from 'eazychart-core';
import { useData } from 'hooks/useData';
import { useSnapshot } from 'hooks/useSnapshot';
import { RawDatum } from 'interfaces/chart';
import { FC, useMemo } from 'react';

const LegendColor: FC<{ color: string }> = ({ color }) => {
  return (
    <span
      className={`legend-color w-3 h-3 inline-block align-middle rounded-full mr-2`}
      style={{
        backgroundColor: color,
      }}
    ></span>
  );
};

export const MapLegend: FC<{
  data: RawDatum[];
  domainKey?: string;
  colors: string[];
}> = ({ data, domainKey, colors }) => {
  const quantiles = useMemo(() => {
    if (domainKey) {
      const quantileScale = new ScaleQuantile({
        domainKey: domainKey,
        range: colors,
      });
      quantileScale.computeScale({ width: 100, height: 100 }, data);
      const domain = quantileScale.scale.domain();
      const min = Math.min(...domain);
      const max = Math.max(...domain);
      const quantiles = [min, ...quantileScale.scale.quantiles(), max];
      // Return intervals
      return quantiles.map((num, i) => [num, quantiles[i + 1]]).slice(0, -1);
    } else {
      return [] as [number, number][];
    }
  }, [data, domainKey, colors]);

  if (quantiles.length === 0) {
    return null;
  }

  return (
    <div className="legend flex">
      <Well title={domainKey}>
        {colors.map((color, idx) => {
          return (
            <div key={color}>
              <LegendColor color={color} />
              <span>{`[ ${quantiles[idx][0].toFixed(2)} - ${quantiles[
                idx
              ][1].toFixed(2)} ]`}</span>
            </div>
          );
        })}
      </Well>
    </div>
  );
};

export const PageCartoLegend: FC = () => {
  const { indicatorData } = useData();
  const snapshot = useSnapshot();

  return (
    <div className="legend-group flex gap-2">
      <MapLegend
        data={indicatorData}
        domainKey={snapshot.mapDomainKey}
        colors={snapshot.colors}
      />
      <MapLegend
        data={indicatorData}
        domainKey={snapshot.bubbleDomainKey}
        colors={snapshot.bubble.colors}
      />
    </div>
  );
};
