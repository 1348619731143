import { Header } from 'components/Layout/Header';
import { usePageCarto } from 'hooks/usePageCarto';
import { FC, useEffect } from 'react';
import { PageCartoChartPanel } from '../Editor/PageCartoChartPanel/PageCartoChartPanel';
import { PageCartoMap } from '../Editor/PageCartoMap';
import { isDesktopEnv } from 'utils/helpers';
import { useNavigate } from 'react-router-dom';
import { useCanEdit } from 'hooks/useCanEdit';

export const PageCartoView: FC = () => {
  const { data } = usePageCarto();
  const navigate = useNavigate();
  const canEdit = useCanEdit();

  useEffect(() => {
    if (!isDesktopEnv()) {
      // Function to handle click events on links
      const handleLinkClick = (event: Event) => {
        // Find the nearest ancestor <a> tag, if any
        const anchor = (event.target as HTMLElement).closest('a');
        // Check if the clicked element is an anchor tag and its href is an internal link
        if (
          anchor &&
          anchor.tagName === 'A' &&
          anchor.getAttribute('href')?.startsWith('/')
        ) {
          event.preventDefault(); // Prevent default link behavior
          const newPath = anchor.getAttribute('href') as string;
          navigate(newPath);
        }
      };

      // Get the container div
      const container = document.querySelector(
        '#pagecarto-content-container'
      ) as Element;
      // Add the event listener
      container.addEventListener('click', handleLinkClick);

      // Cleanup function to remove the event listener
      return () => {
        container.removeEventListener('click', handleLinkClick);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]); // Re-attach the event listener if the data changes

  return (
    <div className="h-full">
      <Header>{data?.data.name}</Header>
      <div className="relative w-[50%] float-right ml-5 z-[999] overflow-hidden">
        <PageCartoMap canEdit={canEdit} />
        <div className="w-[50%] absolute z-50 bottom-0 right-0 opacity-90">
          <PageCartoChartPanel readOnly={true} />
        </div>
      </div>
      <div
        id="pagecarto-content-container"
        className="border-1 border-blue-600 overflow-y-auto inline"
        dangerouslySetInnerHTML={{ __html: data?.data.html || '' }}
      ></div>

      <div style={{ clear: 'both' }}></div>
      <div className="col-span-6 row-span-2 p-4 relative"></div>
      <div className="col-span-6 row-span-1 my-1"></div>
    </div>
  );
};
