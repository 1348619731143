import { useAuth } from 'hooks/useAuth';
import { useService } from 'hooks/useService';
import { ContentType } from 'services/abstract';

type AccountAvatarProps = {
  format?: 'thumbnail' | 'small' | 'medium' | 'large';
  avatarUrl?: string;
  onClick?: () => void;
};

export const AccountAvatar: React.FC<AccountAvatarProps> = ({
  format = 'thumbnail',
  avatarUrl,
  onClick,
}) => {
  const { user } = useAuth();
  const userService = useService(ContentType.USERS);
  const avatarSize = format === 'thumbnail' ? 10 : 16;

  if (!user) {
    return null;
  }

  return (
    <button
      onClick={onClick}
      className="flex items-center max-w-xs text-sm text-white rounded-full focus:outline-none focus:shadow-solid"
    >
      <span
        className={`inline-block w-${avatarSize} h-${avatarSize} overflow-hidden bg-gray-200 rounded-full`}
      >
        <img
          className={`object-cover w-${avatarSize} h-${avatarSize}`}
          src={
            avatarUrl
              ? avatarUrl
              : userService.getAvatarUrl(user.avatar, 'thumbnail')
          }
          alt={user.avatar?.name}
        />
      </span>
    </button>
  );
};
