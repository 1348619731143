import { CloudArrowUpIcon } from '@heroicons/react/24/solid';
import { ApiErrorAlert } from 'components/Alert/ApiErrorMessage';
import { Button } from 'components/Button/Button';
import { useAuth } from 'hooks/useAuth';
import { useService } from 'hooks/useService';
import { useToast } from 'hooks/useToast';
import { ApiError } from 'interfaces/api';
import { UploadedFile } from 'interfaces/file';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { AccountAvatar } from './AccountAvatar';
import { ContentType } from 'services/abstract';

export const AvatarUploadForm: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const { user, refetchUser } = useAuth();
  const { addToast } = useToast();
  const [imageURL, setImageURL] = useState<string>('');
  const userService = useService(ContentType.USERS);

  const {
    mutateAsync: handleUpload,
    isError,
    isLoading,
    error,
  } = useMutation({
    mutationFn: async (data: { file: File }) => {
      return await userService.uploadAvatar(data.file, user?.id as number);
    },
    onSuccess: (_data: UploadedFile) => {
      setFile(null);
      addToast({
        title: `Fichier téléchargé`,
        description: `Avatar mis à jour avec succès`,
        type: 'success',
      });
      refetchUser();
    },
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) {
      addToast({
        title: "Erreur lors de la mise à jour de l'avatar",
        description: 'Erreur inconnue ...',
        type: 'error',
      });
      setFile(null);
    } else if (file.size > 1 * 1024 * 1024) {
      addToast({
        title: "Erreur lors de la mise à jour de l'avatar",
        description: 'La taille du fichier ne doit pas dépasser 1 Mo',
        type: 'error',
      });
      setFile(null);
    } else if (!file.type.startsWith('image/')) {
      addToast({
        title: "Erreur lors de la mise à jour de l'avatar",
        description: 'Le fichier doit être une image',
        type: 'error',
      });
      setFile(null);
    } else {
      setFile(file);
      const reader = new FileReader();
      // it's onload event and you forgot (parameters)
      reader.onload = (e) => {
        setImageURL(e?.target?.result as string);
      };
      // you have to declare the file loading
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="flex flex-col items-start">
      <div className="flex flex-col items-start">
        <div className="flex flex-row mb-2">
          <AccountAvatar format="small" avatarUrl={imageURL} />
          <input type="file" onChange={handleInputChange} className="m-4" />
        </div>
        {isError && <ApiErrorAlert error={error as ApiError} />}
        <Button
          onClick={() => file && handleUpload({ file: file })}
          disabled={!file}
          size="md"
          icon={CloudArrowUpIcon}
          isLoading={isLoading}
        >
          {isLoading ? 'Chargement...' : 'Upload'}
        </Button>
      </div>
    </div>
  );
};
