import { ApiCollection, ApiDocument, ApiData } from 'interfaces/api';
import { UploadedFile } from 'interfaces/file';
import { GeoMap, GeoMapStub } from 'interfaces/geo-map';
import { GeoJSON } from 'interfaces/geojson';

export abstract class GeoMapService {
  abstract getGeoMaps(
    page: number,
    pageSize: number,
    userId?: number
  ): Promise<ApiCollection<GeoMap>>;
  abstract createGeoMap(data: GeoMapStub): Promise<ApiDocument<GeoMapStub>>;
  abstract uploadGeoJson(file: File): Promise<UploadedFile>;
  abstract getGeoJson(geoJSON: ApiData<UploadedFile>): Promise<GeoJSON>;
  abstract getGeoMapThumbnailUrlById(id: number): string;
}
