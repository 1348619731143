import { ApiDocument, ApiCollection } from 'interfaces/api';
import { Chart } from 'interfaces/chart';

export abstract class ChartService {
  abstract createChart(data: Chart): Promise<ApiDocument<Chart>>;
  abstract getChartById(id: number): Promise<ApiDocument<Chart>>;
  abstract getChartsByCartoPage(
    pageCartoId: number
  ): Promise<ApiCollection<Chart>>;
  abstract updateChart(
    id: number,
    data: Partial<Chart>
  ): Promise<ApiDocument<Chart>>;
  abstract deleteChart(id: number): Promise<unknown>;
}
