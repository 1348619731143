import { ComponentProps, FC } from 'react';
import classNames from 'classnames';

type Color = 'gray' | 'green' | 'red';

export type LabelProps = ComponentProps<'label'> & {
  color?: Color;
};

const colorClasses: Record<Color, string> = {
  gray: 'text-gray-900',
  green: 'text-green-700',
  red: 'text-red-700',
};

export const Label: FC<LabelProps> = ({
  children,
  color = 'gray',
  className,
  ...props
}) => (
  <label
    className={classNames(
      'text-sm font-medium',
      colorClasses[color],
      className
    )}
    {...props}
  >
    {children}
  </label>
);
