import { ApiData, ApiDocument } from 'interfaces/api';
import { IndicatorStub } from 'interfaces/indicator';
import { IndicatorService } from '../abstract/indicator';
import { Strapi } from './strapi';
import { ContentType } from 'services/abstract';

export class RemoteIndicatorService extends IndicatorService {
  constructor(private strapiService: Strapi) {
    super();
  }

  async getIndicatorsByPageCartoId(
    pageCarto: number
  ): Promise<ApiData<IndicatorStub>[]> {
    const { data: indicators } = await this.strapiService.get<IndicatorStub>(
      ContentType.INDICATOR,
      {
        filters: {
          page_carto: { $eq: pageCarto },
        },
      }
    );
    return indicators;
  }

  async addIndicatorToPageCarto(
    pageCartoId: number,
    indicator: IndicatorStub
  ): Promise<ApiDocument<IndicatorStub>> {
    return await this.strapiService.create<IndicatorStub>(
      ContentType.INDICATOR,
      {
        ...indicator,
        page_carto: pageCartoId,
      }
    );
  }
}
