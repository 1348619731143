import config from 'config';
import { ApiCollection, ApiData, ApiDocument } from 'interfaces/api';
import { UploadedFile } from 'interfaces/file';
import { GeoMap, GeoMapStub } from 'interfaces/geo-map';
import { validateGeoJsonFile } from 'utils/file';
import { QueryParams, Strapi } from './strapi';
import { GeoMapService } from '../abstract/geo-map';
import { GeoJSON } from 'interfaces/geojson';
import { ContentType } from 'services/abstract';

export class RemoteGeoMapService extends GeoMapService {
  constructor(private strapiService: Strapi) {
    super();
  }

  async getGeoMaps(
    page: number,
    pageSize: number,
    userId?: number
  ): Promise<ApiCollection<GeoMap>> {
    return this.strapiService.get<GeoMap>(ContentType.GEO_MAPS, {
      filters: {
        owner: userId ? { id: userId } : {},
      } as QueryParams['filters'],
      populate: '*',
      sort: 'createdAt:desc',
      pagination: {
        page: page,
        pageSize: pageSize,
      },
    });
  }

  async createGeoMap(data: GeoMapStub): Promise<ApiDocument<GeoMapStub>> {
    return await this.strapiService.create<GeoMapStub>(
      ContentType.GEO_MAPS,
      data
    );
  }

  async uploadGeoJson(file: File): Promise<UploadedFile> {
    const fileToUpload = await this.createGeoJson(file);
    return await this.strapiService.uploadFile(
      fileToUpload,
      'api::geo-map.geo-map'
    );
  }

  async getGeoJson(geoJSON: ApiData<UploadedFile>) {
    return await this.strapiService.request.get<void, GeoJSON>(geoJSON.url);
  }

  getGeoMapThumbnailUrlById(id: number): string {
    return `${config.API_URL}/api/geo-maps/thumbnail/${id}`;
  }

  private async createGeoJson(file: File): Promise<File> {
    if (file.type === 'application/json') {
      return file;
    }
    const GeoJsonFileContent = await validateGeoJsonFile(file);
    return new File([JSON.stringify(GeoJsonFileContent)], `${file.name}.json`, {
      type: 'application/json',
    });
  }
}
