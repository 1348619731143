import { ApiDocument } from 'interfaces/api';
import { GeoProjectionType } from 'interfaces/geojson';
import {
  Snapshot,
  SnapshotBubbleConfig,
  SnapshotConfigs,
  SnapshotStub,
} from 'interfaces/snapshot';
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import { useData } from './useData';
import { usePageCarto } from './usePageCarto';
import { useService } from './useService';
import { useToast } from './useToast';
import { ContentType } from 'services/abstract';

type SnapshotContextType = SnapshotConfigs & {
  updateIndicatorId: (indicatorId: number) => void;
  updateProjection: (projection: GeoProjectionType) => void;
  updateColors: (colors: string[]) => void;
  updateBubbleConfig: (
    key: keyof SnapshotBubbleConfig,
    config: SnapshotBubbleConfig[keyof SnapshotBubbleConfig]
  ) => void;
  mapDomainKey: string | undefined;
  bubbleDomainKey: string | undefined;
  addSnapshot: () => Promise<ApiDocument<SnapshotStub>>;
};

const DEFAULT_SNAPSHOT_CONFIGS: SnapshotConfigs = {
  indicatorId: 0,
  projection: 'geoMercator',
  colors: ['#FFDC8C', '#FFB66E', '#FF8538', '#F24F12', '#E50916'],
  bubble: {
    indicatorId: 0,
    colors: ['#00FFFF', '#00FF00'],
    minRadius: 10,
    maxRadius: 50,
    opacity: 0.5,
  },
};

const initialContext: SnapshotContextType = {
  ...DEFAULT_SNAPSHOT_CONFIGS,
  updateIndicatorId: (_indicatorId: number) => {
    /** noop **/
  },
  updateProjection: (_projection: GeoProjectionType) => {
    /** noop **/
  },
  updateColors: (_colors: string[]) => {
    /** noop **/
  },
  updateBubbleConfig: (
    _key: keyof SnapshotBubbleConfig,
    _config: Partial<SnapshotBubbleConfig[keyof SnapshotBubbleConfig]>
  ) => {
    /** noop **/
  },
  mapDomainKey: undefined,
  bubbleDomainKey: undefined,
  addSnapshot: () => {
    return Promise.reject();
  },
};

const SnapshotContext = createContext<SnapshotContextType>(initialContext);

type SnapshotProviderProps = {
  snapshot?: ApiDocument<Snapshot>;
  children: ReactNode;
};

export const SnapshotProvider: FC<SnapshotProviderProps> = ({
  snapshot,
  children,
}) => {
  const { indicators, pageCartoId, pageCartoConfigs } = usePageCarto();
  const { selectedGeoCode } = useData();
  const [configs, setConfig] = useState<SnapshotConfigs>(
    pageCartoConfigs ? pageCartoConfigs : DEFAULT_SNAPSHOT_CONFIGS
  );
  const { addToast } = useToast();
  const snapshotService = useService(ContentType.SNAPSHOTS);
  const pageCartoService = useService(ContentType.PAGE_CARTOS);

  const { mutateAsync: updatePageCartoConfig } = useMutation({
    mutationFn: async (newConfigs: SnapshotConfigs) => {
      return await pageCartoService.updatePageCarto(pageCartoId, {
        configs: newConfigs,
      });
    },
  });

  const { mutateAsync: addSnapshot } = useMutation({
    mutationFn: async () => {
      return await snapshotService.createSnapshot({
        ...configs,
        page_carto: pageCartoId,
        geoCode: selectedGeoCode || '',
      });
    },
    onSuccess: () => {
      addToast({
        title: `Snapshot`,
        type: 'success',
        description: `Le snapshot a été crée avec succès`,
      });
    },
    onError: (error) => {
      addToast({
        title: 'Echec lors de la sauvegarde du snapshot',
        type: 'error',
        description: JSON.stringify(error),
      });
    },
  });

  const updateConfigs = (configs: SnapshotConfigs) => {
    setConfig(configs);
    updatePageCartoConfig(configs);
  };

  const updateIndicatorId = (indicatorId: number) => {
    updateConfigs({
      ...configs,
      indicatorId,
    });
  };

  const updateProjection = (projection: GeoProjectionType) => {
    updateConfigs({
      ...configs,
      projection,
    });
  };

  const updateColors = (colors: string[]) => {
    updateConfigs({
      ...configs,
      colors,
    });
  };

  const updateBubbleConfig = (
    key: keyof SnapshotBubbleConfig,
    config: SnapshotBubbleConfig[keyof SnapshotBubbleConfig]
  ) => {
    updateConfigs({
      ...configs,
      bubble: {
        ...configs.bubble,
        [key]: config,
      },
    });
  };

  const mapDomainKey = useMemo(
    () => indicators.find(({ id }) => id === configs.indicatorId)?.name,
    [indicators, configs.indicatorId]
  );

  const bubbleDomainKey = useMemo(
    () => indicators.find(({ id }) => id === configs.bubble.indicatorId)?.name,
    [indicators, configs.bubble.indicatorId]
  );

  useEffect(() => {
    if (snapshot?.data) {
      const { indicatorId, projection, colors, bubble } = snapshot.data;
      setConfig({
        indicatorId,
        projection,
        colors,
        bubble,
      });
    }
  }, [snapshot]);

  return (
    <SnapshotContext.Provider
      value={{
        ...configs,
        updateIndicatorId,
        updateProjection,
        updateColors,
        updateBubbleConfig,
        mapDomainKey,
        bubbleDomainKey,
        addSnapshot,
      }}
    >
      {children}
    </SnapshotContext.Provider>
  );
};

export const useSnapshot = () => {
  return useContext(SnapshotContext);
};
