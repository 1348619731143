import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { usePageCarto } from 'hooks/usePageCarto';
import { useService } from 'hooks/useService';
import { $getRoot, $insertNodes } from 'lexical';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ContentType } from 'services/abstract';
import { debounce } from 'utils/debounce';

const AutoSavePlugin = () => {
  const queryClient = useQueryClient();
  const [editor] = useLexicalComposerContext();
  const { data } = usePageCarto();
  const [isMounted, setIsMounted] = useState(false);
  const isInitialized = useRef(false);
  const pageCartoService = useService(ContentType.PAGE_CARTOS);

  const { mutate: updatePageCartoHtml } = useMutation({
    mutationFn: async (html: string) => {
      await pageCartoService.updatePageCarto(data?.data.id || 0, {
        html: html,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['page-carto', data?.data.id],
      });
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdate = useCallback(debounce(updatePageCartoHtml), [
    updatePageCartoHtml,
  ]);

  useEffect(() => {
    if (isMounted && !isInitialized.current) {
      editor.update(() => {
        // In the browser you can use the native DOMParser API to parse the HTML string.
        const parser = new DOMParser();
        const dom = parser.parseFromString(data?.data.html || '', 'text/html');

        const nodes = $generateNodesFromDOM(editor, dom);

        // Select the root
        $getRoot().select();

        // Insert them at a selection.
        $insertNodes(nodes);
        isInitialized.current = true;
      });
      editor.registerUpdateListener(({ editorState }) => {
        // The latest EditorState can be found as `editorState`.
        // To read the contents of the EditorState, use the following API:

        editorState.read(() => {
          const html = $generateHtmlFromNodes(editor, null);
          debounceUpdate(html);
          // Just like editor.update(), .read() expects a closure where you can use
          // the $ prefixed helper functions.
        });
      });
      isInitialized.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return null;
};

export default AutoSavePlugin;
