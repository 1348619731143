import React from 'react';

const ChartPlaceholder = (
  props: React.SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg viewBox="0 0 2000 1200" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 1200h133.333V39.54q0-8-8-8H8q-8 0-8 8ZM166.667 1200H300V79.52q0-8-8-8H174.667q-8 0-8 8ZM333.333 1200h133.334V186.16q0-8-8-8H341.333q-8 0-8 8ZM500 1200h133.333V301.348q0-8-8-8H508q-8 0-8 8ZM666.667 1200H800V300.951q0-8-8-8H674.667q-8 0-8 8ZM833.333 1200h133.334V398.036q0-8-8-8H841.333q-8 0-8 8ZM1000 1200h133.333V337.776q0-8-8-8H1008q-8 0-8 8ZM1166.667 1200H1300V606.526q0-8-8-8h-117.333q-8 0-8 8ZM1333.333 1200h133.334V562.713q0-8-8-8h-117.334q-8 0-8 8ZM1500 1200h133.333V858.874q0-8-8-8H1508q-8 0-8 8ZM1666.667 1200H1800v-102.644q0-8-8-8h-117.333q-8 0-8 8ZM1833.333 1200h133.334V752.194q0-8-8-8h-117.334q-8 0-8 8Z"
        fill="#e5e5e5"
      />
    </svg>
  );
};
export default ChartPlaceholder;
