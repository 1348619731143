import { ApiData } from 'interfaces/api';
import { UploadedFile } from 'interfaces/file';
import { User, UserAuthResponse, UserSignUpFields } from 'interfaces/user';
import { AVATAR_THUMBNAIL_PLACEHOLDER, getImageUrl } from 'utils/file';
import { UserService } from '../abstract/user';
import { Strapi } from './strapi';

export class RemoteUserService extends UserService {
  constructor(private strapiService: Strapi) {
    super();
  }

  async getCurrentUser(token: string): Promise<User> {
    return await this.strapiService.currentUser(token);
  }

  async updateCurrentUser(
    userId: number,
    data: UserSignUpFields
  ): Promise<User> {
    return await this.strapiService.updateCurrentUser(userId, data);
  }

  async sendForgotPasswordEmail(email: string): Promise<boolean> {
    return await this.strapiService.forgotPassword(email);
  }

  async login(email: string, password: string): Promise<UserAuthResponse> {
    return await this.strapiService.login(email, password);
  }

  async resetPassword(
    code: string,
    password: string,
    password2: string
  ): Promise<UserAuthResponse> {
    return await this.strapiService.resetPassword(code, password, password2);
  }

  async signUp(newUser: UserSignUpFields): Promise<UserAuthResponse> {
    return await this.strapiService.register(newUser);
  }

  async uploadAvatar(file: File, refId: number): Promise<UploadedFile> {
    return await this.strapiService.uploadFile(
      file,
      'plugin::users-permissions.user',
      `${refId}_thumbnail.png`,
      refId,
      'avatar'
    );
  }

  getAvatarUrl = (
    cover: ApiData<UploadedFile>,
    format: 'thumbnail' | 'small' | 'medium' | 'large' = 'thumbnail'
  ) => {
    return getImageUrl(cover, format, AVATAR_THUMBNAIL_PLACEHOLDER);
  };

  logout() {
    this.strapiService.logoutCurrentUser();
  }
}
