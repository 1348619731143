import { Tag } from 'interfaces/tag';
import { QueryParams, Strapi } from './strapi';
import { ApiCollection, ApiDocument } from 'interfaces/api';
import { TagService } from '../abstract/tag';
import { ContentType } from 'services/abstract';

export class RemoteTagService extends TagService {
  constructor(private strapiService: Strapi) {
    super();
  }

  async getAllTags(): Promise<ApiCollection<Tag>> {
    return await this.strapiService.get<Tag>(ContentType.TAGS, {
      populate: '*',
      sort: ['createdAt:asc'],
      pagination: {
        limit: -1,
      },
    });
  }

  async getAllTagsByOwner(ownerId: number): Promise<ApiCollection<Tag>> {
    return await this.strapiService.get<Tag>(ContentType.TAGS, {
      populate: {
        page_cartos: {
          populate: {
            owner: true,
          },
        },
      },
      filters: {
        page_cartos: {
          owner: {
            id: {
              $eq: ownerId,
            },
          },
        },
      } as QueryParams['filters'],
      pagination: {
        limit: -1,
      },
    });
  }

  async createTag(data: Tag): Promise<ApiDocument<Tag>> {
    return await this.strapiService.create<Tag>(ContentType.TAGS, data);
  }
}
