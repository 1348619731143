import { isDesktopEnv } from 'utils/helpers';

export const getServices = () => {
  if (isDesktopEnv()) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    return require('../electron/services').default;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    return require('./remote').default;
  }
};
