import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { ApiErrorAlert } from 'components/Alert/ApiErrorMessage';
import { Badge } from 'components/Badge/Badge';
import { AutoCompleteInput } from 'components/Inputs/AutoCompleteInput';
import { LoadingMessage } from 'components/Loader/LoadingMessage';
import { useService } from 'hooks/useService';
import { useToast } from 'hooks/useToast';
import { ApiData, ApiDocument, ApiError } from 'interfaces/api';
import { Tag } from 'interfaces/tag';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ContentType } from 'services/abstract';

interface TagsSelectorProps {
  onChange: (tags: number[]) => void;
  selected?: number[];
}

export const TagsSelector: React.FC<TagsSelectorProps> = React.forwardRef<
  HTMLDivElement,
  TagsSelectorProps
>(({ onChange, selected = [] }, ref) => {
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>(selected);
  const { addToast } = useToast();
  const tagService = useService(ContentType.TAGS);

  const {
    data: response,
    isError,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['tags'],
    queryFn: async () => await tagService.getAllTags(),
  });

  const createTagMutation = useMutation<ApiDocument<Tag>, ApiError, Tag>({
    mutationFn: async (newTag: Tag): Promise<ApiDocument<Tag>> => {
      return await tagService.createTag(newTag);
    },
    onSuccess: async ({ data }: ApiDocument<Tag>) => {
      addToast({
        title: 'Nouveau tag créé',
        description: 'Votre Tag a été ajouté avec succès',
        type: 'success',
      });
      await refetch();
      handleTagSelect(data);
      onChange([...selectedTagIds, data.id]);
    },
  });

  const tags = useMemo(() => response?.data || [], [response]);
  const selectedTags = useMemo(() => {
    return selectedTagIds.map((id) => {
      return tags.find((tag) => id === tag.id) as ApiData<Tag>;
    });
  }, [tags, selectedTagIds]);

  if (isLoading) {
    return <LoadingMessage data-testid="tags-loading-message" />;
  }

  if (isError) {
    return (
      <ApiErrorAlert error={error as ApiError} data-testid="error-message" />
    );
  }

  const handleTagSelect = (tag: ApiData<Tag>) => {
    const isSelected = selectedTagIds.includes(tag.id);
    if (!isSelected) {
      const selectedIds = [...selectedTagIds, tag.id];
      setSelectedTagIds(selectedIds);
      onChange(selectedIds);
    }
  };

  const handleTagDeselect = (tag: ApiData<Tag>) => {
    const updatedTags = selectedTags.filter(
      (selectedTag) => selectedTag.id !== tag.id
    );
    setSelectedTagIds(updatedTags.map((tag) => tag.id));
    onChange(updatedTags.map((tag) => tag.id));
  };

  const handleSubmit = (name: string) => {
    const alreadyExist = tags.find((t) => t.name === name);
    if (!alreadyExist) {
      createTagMutation.mutateAsync({ name });
    }
  };

  return (
    <div className="w-3/4" ref={ref}>
      <div className="flex flex-wrap">
        {selectedTags.map((tag) => (
          <Badge key={tag.id} className="my-1" data-testid="selected-tags">
            {tag.name}
            <XMarkIcon
              onClick={() => handleTagDeselect(tag)}
              className="cursor-pointer ml-2 h-4 w-4 inline-block"
            />
          </Badge>
        ))}
        <AutoCompleteInput<ApiData<Tag>>
          options={tags.filter(({ id }) => !selectedTagIds.includes(id))}
          labelField={'name'}
          onSelect={handleTagSelect}
          onCreate={handleSubmit}
          enableComboBox={false}
          placeholder={'Ajouter des tags ....'}
          emptyMessage={'Aucun tag trouvé! Tapez "Entrée" pour ajouter.'}
        />
      </div>
    </div>
  );
});
