import { Snapshot, SnapshotStub } from 'interfaces/snapshot';
import { Strapi } from './strapi';
import { ApiDocument } from 'interfaces/api';
import { SnapshotService } from '../abstract/snapshot';
import { ContentType } from 'services/abstract';

export class RemoteSnapshotService extends SnapshotService {
  constructor(private strapiService: Strapi) {
    super();
  }

  async createSnapshot(data: SnapshotStub): Promise<ApiDocument<SnapshotStub>> {
    return await this.strapiService.create<SnapshotStub>(
      ContentType.SNAPSHOTS,
      data
    );
  }

  async getSnapshotById(id: number): Promise<ApiDocument<Snapshot>> {
    return await this.strapiService.getById<Snapshot>(
      ContentType.SNAPSHOTS,
      id
    );
  }
}
