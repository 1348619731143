import { ApiDocument, ApiCollection, ApiData } from 'interfaces/api';
import { UploadedFile } from 'interfaces/file';
import { PageCartoStub, PageCarto } from 'interfaces/page-carto';
import { User } from 'interfaces/user';

export abstract class PageCartoService {
  abstract createPageCarto(
    data: PageCartoStub
  ): Promise<ApiDocument<PageCartoStub>>;
  abstract getLatestPageCartos(
    page: number,
    pageSize: number
  ): Promise<ApiCollection<PageCarto>>;
  abstract getPageCartoById(id: number): Promise<ApiDocument<PageCarto>>;
  abstract uploadCsv(file: File): Promise<UploadedFile>;
  abstract getPageCartoByTagsAndSearch(
    page: number,
    searchKeywords: string,
    selectedTags: number[],
    user: User | undefined
  ): Promise<ApiCollection<PageCarto>>;
  abstract updatePageCarto(
    id: number,
    data: Partial<PageCartoStub>
  ): Promise<ApiDocument<PageCarto>>;
  abstract uploadCover(file: File, refId: number): Promise<UploadedFile>;
  abstract deletePageCarto(id: number): Promise<void>;
  abstract getImageUrl(
    cover: ApiData<UploadedFile> | undefined,
    format: 'thumbnail' | 'small' | 'medium' | 'large'
  ): string;
}
