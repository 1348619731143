export function adjustColorComponent(
  component: number,
  adjustment: number
): number {
  // Ensure the adjusted value remains within the 0-255 range
  const newValue = component + adjustment;
  if (newValue > 255) {
    return newValue - 255;
  } else if (newValue < 0) {
    return 255 + newValue;
  } else {
    return newValue;
  }
}

export function generateNewColor(lastColorHex: string): string {
  // Random adjustment between 20 and 50
  let adjustment = Math.floor(Math.random() * (51 - 20)) + 20;
  const cycle = Math.floor(Math.random() * 3) + 1;

  // Remove the '#' if present
  const hex = lastColorHex.replace('#', '');

  // Parse the RGB components from the hex color
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Determine which component to adjust based on the cycle
  adjustment = cycle % 2 === 0 ? -adjustment : adjustment; // Alternate between increasing and decreasing

  let newR = r,
    newG = g,
    newB = b;
  switch (cycle % 3) {
    case 0:
      newR = adjustColorComponent(r, adjustment);
      break;
    case 1:
      newG = adjustColorComponent(g, adjustment);
      break;
    case 2:
      newB = adjustColorComponent(b, adjustment);
      break;
  }

  // Convert the adjusted components back to hex format
  const newHex = `#${newR.toString(16).padStart(2, '0')}${newG
    .toString(16)
    .padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`.toUpperCase();

  return newHex;
}
